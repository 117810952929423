<template>
  <v-layout>
    <v-row>
      <v-col cols="2">
        <nav-bar></nav-bar>
      </v-col>
      <v-col cols="9">
        <router-view />
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import NavBar from "@/components/NavBar";
export default {
  name: "App",
  components: { NavBar },
};
</script>

<style>
</style>
