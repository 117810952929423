
import { debounce } from "lodash";

export default {
  name: "InvoicesTable",
  data() {
    return {
      invoices: [],
      search: null,
      status: "PROCESSING",
      statuses: ["PROCESSING", "ACCEPTED", "DECLINED", "ALL"],
      deposit: "ALL",
      deposits: ["PRIMARY", "SECONDARY", "ALL"],
      itemsPerPage: 10,
      itemsPerPageOptions: [
        { value: 10, title: "10" },
        { value: 25, title: "25" },
        { value: 50, title: "50" },
        { value: 100, title: "100" },
      ],
      headers: [
        { title: 'ID аккаунта', key: 'accountId', value: 'accountId', align: "left", sortable: false },
        { title: "ФИО", key: "fio", value: "fio" },
        { title: "Вид счёта", key: "deposit", value: "deposit" },
        { title: "Сумма", key: "value", value: "value" },
        { title: 'Счёт получателя', key: 'payeesAccount', value: 'payeesAccount' },
        { title: "БИК", key: "bic", value: "bic" },
        { title: "Банк получателя", key: "payeesBank", value: "payeesBank" },
        { title: 'Корр счёт', key: 'correspondentAccount', value: 'correspondentAccount' },
        { title: "ИНН", key: "inn", value: "inn" },
        { title: "КПП", key: "kpp", value: "kpp" },
        { title: "Статус", key: "status", value: "status" },
        { title: "Дата создания", key: "createdAt", value: "createdAt" },
        { title: "", key: "invoiceUid", value: "invoiceUid" },
      ],
      totalElements: 0,
      currentPage: 1,
      loading: true,
    };
  },
  watch: {
    status() {
      this.loadItems();
    },
  },
  methods: {
    loadItems(req) {
      debounce(() => {
        this.currentPage = req ? Math.max(req.page, 1) : this.currentPage;
        const size = this.itemsPerPage;
        this.axios
          .get(`${process.env.VUE_APP_BACKEND_BASE_URL}/withdrawal/invoices`, {
            params: {
              page: this.currentPage,
              size,
              status: this.status === "ALL" ? null : this.status,
              deposit: this.deposit === "ALL" ? null : this.deposit,
              search: this.search,
            },
          })
          .then((res) => {
            this.loading = false;
            console.log(res.data);
            this.totalElements = res.data.count;
            this.invoices = res.data.table.map((invoice) => {
              return {
                accountId: invoice.accountId,
                status: invoice.status,
                fio: invoice.fio,
                deposit: invoice.deposit,
                value: invoice.value,
                payeesAccount: invoice.payeesAccount,
                bic: invoice.bic,
                payeesBank: invoice.payeesBank,
                correspondentAccount: invoice.correspondentAccount,
                inn: invoice.inn,
                kpp: invoice.kpp,
                createdAt: invoice.createdAt,
                invoiceUid: invoice.invoiceUid,
              };
            });
          })
          .catch((err) => {
            console.error(err);
            this.invoices = [];
          });
      }, 500)();
    },
    approve(invoice) {
      this.axios
        .patch(
          `${process.env.VUE_APP_BACKEND_BASE_URL}/withdrawal/invoice/${invoice.invoiceUid.toString()}?status=ACCEPTED`
        )
        .then((_) => {
          this.loadItems({ page: this.currentPage });
        });
    },
    decline(invoice) {
      this.axios
        .patch(
          `${process.env.VUE_APP_BACKEND_BASE_URL}/withdrawal/invoice/${invoice.invoiceUid.toString()}?status=DECLINED`
        )
        .then((_) => {
          this.loadItems({ page: this.currentPage });
        });
    },
  },
};
