import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_offering_bids_table = _resolveComponent("offering-bids-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_select, {
          label: "Размещения",
          modelValue: $data.selectedOfferingId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.selectedOfferingId) = $event)),
          items: $data.offerings,
          loading: $data.offeringsLoading,
          "item-title": _ctx.name,
          "item-value": _ctx.uid
        }, null, 8, ["modelValue", "items", "loading", "item-title", "item-value"]),
        _createVNode(_component_v_checkbox)
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_offering_bids_table, { "offering-uid": "selectedOfferingId" })
      ]),
      _: 1
    })
  ], 64))
}