
export default {
  name: "OfferingBidsTable",
  props: {
    offeringUid: String,
  },
  mounted() {
    this.axios.get(`${process.env.VUE_APP_BACKEND_BASE_URL}/offerings/3ee3087a-f663-413f-81e5-8320dd04e75a/bids`)
      .then(res => {
        this.bids = res.data;
        this.totalStocks = this.bids.reduce((prev, curr) => prev + curr.stocks, 0);
        this.totalMarketValue = this.bids.reduce((prev, curr) => prev + curr.marketValue, 0);
        this.totalOfferingPercent = this.bids.reduce((prev, curr) => prev + curr.offeringPercent, 0);
      })
      .catch((err) => {
        console.error(err);
        this.bids = [];
      });
  },
  data() {
    return {
      bids: [],
      totalStocks: 0,
      totalMarketValue: 0,
      totalOfferingPercent: 0,
    };
  },
};
