import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionModal = _resolveComponent("TransactionModal")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_TransactionModal, {
          accountsData: $data.accountsData,
          "onUpdate:accountsData": _cache[0] || (_cache[0] = ($event: any) => (($data.accountsData) = $event)),
          onSuccess: $options.loadItems
        }, null, 8, ["accountsData", "onSuccess"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createTextVNode(" Транзакции ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              modelValue: $data.status,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.status) = $event)),
              label: "Статус транзакции",
              items: $data.statuses,
              density: "comfortable"
            }, null, 8, ["modelValue", "items"]),
            _createVNode(_component_v_combobox, {
              modelValue: $data.txType,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.txType) = $event)),
              label: "Тип транзакции",
              items: $data.txTypes,
              density: "comfortable"
            }, null, 8, ["modelValue", "items"]),
            _createVNode(_component_v_combobox, {
              modelValue: $data.deposit,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.deposit) = $event)),
              label: "Вид счёта",
              items: $data.deposits,
              density: "comfortable"
            }, null, 8, ["modelValue", "items"]),
            _createVNode(_component_v_text_field, {
              modelValue: $data.search,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.search) = $event)),
              "append-icon": "mdi-magnify",
              label: "Поиск",
              "single-line": true,
              "hide-details": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_data_table_server, {
          headers: $data.headers,
          items: $data.transactions,
          loading: $data.loading,
          search: $data.search,
          "items-length": $data.totalElements,
          "items-per-page": $data.itemsPerPage,
          "no-data-text": 'Нет данных',
          "items-per-page-text": 'Размер страницы',
          "items-per-page-options": $data.itemsPerPageOptions,
          "item-value": "name",
          class: "elevation-1",
          "onUpdate:options": $options.loadItems
        }, {
          item: _withCtx(({ item }) => [
            _createElementVNode("tr", null, [
              _createElementVNode("td", null, _toDisplayString(item.columns.humanReadableId), 1),
              _createElementVNode("td", null, _toDisplayString(item.columns.internalId), 1),
              _createElementVNode("td", null, _toDisplayString(item.columns.fio), 1),
              _createElementVNode("td", null, _toDisplayString(item.columns.type), 1),
              _createElementVNode("td", null, _toDisplayString(new Intl.NumberFormat('fr-FR').format(item.columns.value)), 1),
              _createElementVNode("td", null, _toDisplayString(item.columns.deposit), 1),
              _createElementVNode("td", null, _toDisplayString(item.columns.status), 1),
              _createElementVNode("td", null, _toDisplayString(new Date(item.columns.createdAtTs)), 1),
              _createElementVNode("td", null, [
                (item.columns.status === 'IN_PROGRESS')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_v_btn, {
                        onClick: ($event: any) => ($options.approve(item.columns))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Подтвердить")
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_v_btn, {
                        onClick: ($event: any) => ($options.decline(item.columns))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Отклонить")
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }, 8, ["headers", "items", "loading", "search", "items-length", "items-per-page", "items-per-page-options", "onUpdate:options"])
      ]),
      _: 1
    })
  ], 64))
}