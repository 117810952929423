<template>
  <v-row class="h-auto">
    <v-col>
      <v-btn variant="flat" @click="goBack">&lt; Назад</v-btn>
      <v-card :loading="loadings.account">
        <v-card-title>Личная информация</v-card-title>
        <v-card-item>
          <p><b>ID: </b>{{ account.id }}</p>
          <p><b>ФИО: </b>{{ account.lastName + ' ' + account.firstName }}</p>
          <p><b>E-mail: </b>{{ account.email }}</p>
          <p><b>Телефон: </b>{{ account.phone }}</p>
          <p><b>Категория: </b>{{ account.category }}</p>
          <p>
            <v-chip
                class="ma-2"
                color="green"
                text-color="white"
                v-if="account.qualified === 'QUALIFIED'"
            >
              Квалифицирован
            </v-chip>
            <v-chip
                class="ma-2"
                color="green"
                text-color="white"
                v-if="account.kycPassed"
            >
              Процедура KYC пройдена
            </v-chip>
            <v-chip
                class="ma-2"
                color="green"
                text-color="white"
                v-if="account.dossierPassed"
            >
              Анкета заполнена
            </v-chip>
          </p>
        </v-card-item>
      </v-card>
    </v-col>
    <v-col :hidden="!kyc.requestExists">
      <v-card :loading="loadings.kyc">
        <v-card-title>Процедура KYC</v-card-title>
        <v-card-item>
          <v-col>
            <p><b>ФИО: </b>{{ kyc.passportLastName + ' ' + kyc.passportFirstName + ' ' + kyc.passportMiddleName }}</p>
            <p><b>Дата рождения: </b>{{ new Date(kyc.dateOfBirth).toDateString() }}</p>
            <p><b>Гражданство: </b>{{ kyc.citizenship }}</p>
            <p><b>Серия и номер паспорта: </b>{{ kyc.passportNumber }}</p>
            <p><b>Орган, выдавший паспорт: </b>{{ kyc.passportIssuedBy }}</p>
            <p><b>Дата выдачи паспорта: </b>{{ new Date(kyc.passportIssueDate).toDateString() }}</p>
            <p><b>Код органа, выдавшего паспорт: </b>{{ kyc.passportIssuerCode }}</p>
            <p><b>Место рождения: </b>{{ kyc.birthPlace }}</p>
            <p><b>Адрес регистрации: </b>{{ kyc.registrationAddress }}</p>
            <p v-if="kyc.snils"><b>СНИЛС: </b>{{ kyc.snils }}</p>
            <p v-if="kyc.inn"><b>ИНН: </b>{{ kyc.inn }}</p>
          </v-col>
        </v-card-item>
        <v-card-actions>
          <v-btn color="deep-purple-accent-4" @click="loadPhoto" right>Скачать паспорт</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import OfferingBidsTable from "@/components/OfferingBidsTable";
import MoneyTransactions from "@/components/MoneyTransactions";
export default {
  name: "AccountView",
  data() {
    return {
      loadings: {
        account: true,
        kyc: true
      },
      account: {
        fio: 'A B C',
        email: 'test@test.com',
        phone: '+79999999999',
        category: 'INVESTOR',
        createdAt: new Date(),
        qualified: false,
        kycPassed: false,
        dossierPassed: false,
      },
      kyc: {
        requestExists: false,
        passportLastName: 'A',
        passportFirstName: 'B',
        passportMiddleName: 'C',
        gender: 'MALE',
        dateOfBirth: '1994-11-23',
        citizenship: 'РФ',
        passportNumber: '1111 111111',
        passportIssuedBy: 'Отделом УФМС России по Калининградской обл. Ленинградского р-на гор. Калининграда',
        passportIssueDate: '2015-02-04',
        passportIssuerCode: '390-003',
        placeOfBirth: 'гор. Калининград',
        registrationAddress: 'sadsadsad',
        userUid: 'eee3feb7-a364-43f7-a2b7-8e1bcb9bcdf9',
        photo: null,
      }
    }
  },
  computed: {
    passportPhotoDataUrl() {
      return this.loadPhoto();
    }
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    async loadAccount() {
      const uid = this.$route.params.id || this.$route.account.uid;
      this.axios.get(`${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/accounts/${uid}`).then(
          res => {
            this.account = res.data;
          }
      ).finally(() => this.loadings.account = false);

      await this.axios.get(`${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/accounts/${uid}/kyc`).then(res => {
        console.log(res);
        this.kyc = res.data;
        this.kyc.requestExists = true;
      }).catch(err => {
        this.kyc.requestExists = false;
      }).finally(() => this.loadings.kyc = false);
    },
    async loadPhoto() {
      const uid = this.$route.params.id;
      return await this.axios.get(`${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/accounts/${uid}/kyc/passport`, {responseType: "blob"})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let expression = "";
            switch (response.headers["content-type"]) {
              case "image/png":
                expression = '.png';
                break;
              case "image/jpg":
              case "image/jpeg":
                expression = '.jpg';
                break;
              case "application/pdf":
                expression = ".pdf";
                break;
            }

            link.setAttribute('download', `passport${expression}`);
            document.body.appendChild(link);
            link.click();
          });
    }
  },
  async mounted() {
    await this.loadAccount();
  }
}
</script>

<style scoped>

</style>
