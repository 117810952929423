import Keycloak from "keycloak-js";

const keycloakInitOptions: any = {
  flow: "standard",
  checkLoginIframe: false,
  onLoad: "login-required",
};

const keycloakConfig = {
  url:
    process.env.VUE_APP_KEYCLOAK_BASE_URL ||
    "https://staging.auth.newinvestments.pfladvisors.com/",
  realm: process.env.VUE_APP_KEYCLOAK_REALM || "new-investments",
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID || "admin-web",
};

const keycloakInstance = new Keycloak(keycloakConfig);

interface AuthenticationCallback<T1 = void, T2 = void> {
  (input: T1): T2;
}

keycloakInstance.onTokenExpired = async () => {
  await keycloakInstance.updateToken(30)
    .catch(err => console.error(err));
};

const login = async (authenticationSuccessfulCallback: AuthenticationCallback) => {
  keycloakInstance.init(keycloakInitOptions)
    .then((authenticated) => {
      if (authenticated) authenticationSuccessfulCallback(null)
    }).catch((e) => {
      console.dir(e);
      console.error(e);
    });
};

const getToken = async () => {
  return keycloakInstance.token;
};

const logout = async () => {
  await keycloakInstance.logout();
};

const KeycloakService = {
  login, 
  getToken, 
  logout
};

export default KeycloakService;
