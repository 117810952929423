
export default {
  name: "AccountsTable",
  data() {
    return {
      accounts: [],
      status: "",
      sortBy: "ASC",
      search: null,
      headers: [
        { title: "ID", key: "id" },
        { title: "Фамилия", key: "lastName" },
        { title: "Имя", key: "firstName" },
        { title: "Отчество", key: "middleName" },
        { title: "KYC", key: "kycPassed" },
        { title: "Досье", key: "dossierPassed" },
        { title: "Квал", key: "qualified" },
        { title: "Legal Status", key: "businessStatus" },
        { title: "Email", key: "email" },
        { title: "Телефон", key: "phone" },
        { title: "UID", key: "uid" },
      ],
      itemsPerPage: 10,
      totalElements: 0,
      loading: true,
      currentPage: 0,
      itemsPerPageOptions: [
        { value: 10, title: "10" },
        { value: 25, title: "25" },
        { value: 50, title: "50" },
        { value: 100, title: "100" },
      ],
      snackbarTimeout: 3000,
      snackbarMessage: "",
      snackbar: false,
    };
  },
  mounted() {
    this.loadItems({page: this.currentPage});
  },
  methods: {
    openAccountView(e, uid) {
      console.log(`Opening ${JSON.stringify(uid)}`);
      this.$router.push(`/accounts/${uid}`);
    },
    loadItems({page, sortBy}) {
      this.currentPage = Math.max(0, page - 1);
      this.axios
        .get(`${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/accounts`, {
          params: {
            page: this.currentPage,
            size: this.itemsPerPage,
            sort: this.sortBy,
            search: this.search,
          },
        })
        .then((res) => {
          this.loading = false;

          const payload = res.data;

          this.accounts = payload.content;
          this.itemsPerPage = payload.size;
          this.totalElements = payload.totalElements;

          console.log(this.accounts);
        });
    },
    qualify(uid) {
      this.axios
        .patch(
          `${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/accounts/${uid}/qualified`,
          {
            qualified: true,
            accountId: uid,
          }
        )
        .then((_) => {
          this.loadItems({page: this.currentPage});

          this.snackbarMessage = `Аккаунт ${uid} помечен как квалифицированный`;
          this.snackbar = true;
        });
    },
  },
};
