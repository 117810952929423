<template>
    <v-navigation-drawer class="bg-deep-orange" permanent="">
      <v-list>
        <router-link to="/accounts" v-if="hasRoleAdmin">
          <v-list-item title="Аккаунты"/>
        </router-link>
        <router-link to="/" v-if="hasRoleAdmin || hasRoleKYC">
          <v-list-item title="KYC"/>
        </router-link>
        <router-link to="/money" v-if="hasRoleAdmin">
          <v-list-item title="Транзакции"/>
        </router-link>
        <router-link to="/bids" v-if="hasRoleAdmin">
          <v-list-item title="Биды"/>
        </router-link>
        <router-link to="/withdrawal" v-if="hasRoleAdmin">
          <v-list-item title="Вывод средств"/>
        </router-link>
        <router-link to="/qualify" v-if="hasRoleAdmin">
          <v-list-item title="Квалы"/>
        </router-link>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout()">
            Logout
          </v-btn>
        </div>
      </template>
<!--    <router-link to="/money">Денежные транзакции</router-link>-->
    </v-navigation-drawer>
</template>

<script>
import keycloakService from "@/services/keycloak-service";
import { getCurrentInstance } from 'vue'

export default {
  name: "NavBar",
  data() {
    const app = getCurrentInstance();
    return  {
      hasRoleAdmin : app.appContext.config.globalProperties.hasRoleAdmin,
      hasRoleKYC : app.appContext.config.globalProperties.hasRoleKYC,
    }
  },
  methods: {
    async logout() {
      await keycloakService.logout();
    }
  }
};
</script>

<style scoped></style>
