
import { v4 as uuid } from "uuid";
import { debounce } from "lodash";

export default {
  name: "TransactionModal",
  methods: {
    loadAccounts() {
      if (!this.search || this.search.length < 3 || this.search.length > 10) {
        return;
      }
      debounce(() => {
        this.axios
          .get(`${process.env.VUE_APP_BACKEND_BASE_URL}/accounts`, {
            params: {
              search: this.search,
              page: this.page,
            },
          })
          .then((res) => {
            this.loading = false;

            const payload = res.data;

            if (!payload.content.empty) {
              this.accountsData = payload.content.map((data) => {
                return {
                  value: (data.id +
                    " - " +
                    data.lastName +
                    " " +
                    data.firstName +
                    " " +
                    (data.middleName || "")) as string,
                  userUid: data.uid,
                };
              });
            } else return;
            this.itemsPerPage = payload.size;
            this.totalElements = payload.totalElements;
            this.currentPage = Math.max(0, this.page - 1);

            console.log(this.accountsData);
          });
      }, 2000)();
    },
    sendTransaction() {
      this.axios.post(`${process.env.VUE_APP_BACKEND_BASE_URL}/accounting/money`,
        {
          id: uuid(), humanReadableId: this.selectedAccount.split(" - ")[0],
          accountId: this.accountsData.find(t => t.value === this.selectedAccount).userUid,
          type: this.selectedType, value: this.valueNumber, status: this.selectedStatus, deposit: this.selectedDeposit
        })
        .then(_ => {
          this.$emit("success");
          this.showModel = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      accountsData: [],
      loading: false,
      search: null,
      status: null,
      selectedAccount: null,
      showModel: false,
      selectedStatus: "IN_PROGRESS",
      statuses: ["IN_PROGRESS", "CONFIRMED"],
      selectedType: "DEPOSIT",
      types: ["DEPOSIT", "WITHDRAW"],
      selectedDeposit: "PRIMARY",
      deposit: ["PRIMARY", "SECONDARY"],
      currentPage: 0,
      itemsPerPage: 10,
      totalElements: 100,
      valueNumber: null,
      filterNonPositive: (val) => {
        if (val <= 0 || isNaN(val)) {
          return "Введите число больше нуля";
        }
        return true;
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
