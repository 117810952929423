
import {KycRequest} from "@/types/KycRequest";

export default {
  name: "KycRejectCommentModal",
  methods: {
    sendDecline() {
      this.axios.patch(`${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/accounts/${this.kycRequest.userUid}/kyc`,
          {status:"FAILED", comment: this.refreshComment()})
          .then((res) => {
            this.$emit("success");
            this.show = false;
          });
    },
    close() {
      this.show = false;
      this.listComment = [];
    },
    refreshComment() {
    let res = "";
    this.listComment.forEach(element => {
      res += element + "\n";
    });
    return res + (this.comment || "").trim();
  },
  },
  props: {
    value: Boolean,
    kycRequest: Object as () => KycRequest,
    tmpComment: String,
  },
  data() {
    return {
      comment: "",
      listComment: [],
    };
  },
  computed: {
    show: {
      get () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.comment = this.tmpComment;
        return this.value;
      },
      set (value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
