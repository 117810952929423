import { createRouter, createWebHistory } from "vue-router";
import KycRequests from "@/components/KycRequests.vue";
import MoneyTransactions from "@/components/MoneyTransactions.vue";
import OfferingBids from "@/components/OfferingBids.vue";
import AccountsTable from "@/components/AccountsTable.vue";
import AccountView from "@/components/AccountView.vue";
import InvoicesTable from "@/components/InvoicesTable.vue";
import QualifyTable from "@/components/QualifyTable.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "KYC",
      component: KycRequests,
    },
    {
      path: "/money",
      name: "MoneyTransactions",
      component: MoneyTransactions,
    },
    {
      path: "/bids",
      name: "Bids",
      component: OfferingBids,
    },
    {
      path: "/accounts",
      name: "Accounts",
      component: AccountsTable,
    },
    {
      path: "/accounts/:id",
      name: "AccountView",
      component: AccountView,
    },
    {
      path: "/withdrawal",
      name: "InvoicesTable",
      component: InvoicesTable,
    },
    {
      path: "/qualify",
      name: "QualifyTable",
      component: QualifyTable,
    },
  ],
});

export default router;
