import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", null, [
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            color: "primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.showModel = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Создать Транзакцию ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_dialog, {
            modelValue: $data.showModel,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.showModel) = $event)),
            width: "1000px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Создать Транзакцию ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_autocomplete, {
                                    modelValue: $data.selectedAccount,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.selectedAccount) = $event)),
                                    search: $data.search,
                                    "onUpdate:search": [
                                      _cache[2] || (_cache[2] = ($event: any) => (($data.search) = $event)),
                                      $options.loadAccounts
                                    ],
                                    loading: $data.loading,
                                    items: 
                      $data.accountsData.map((data) => {
                        return data.value;
                      })
                    ,
                                    readonly: false,
                                    density: "comfortable",
                                    "hide-details": "",
                                    label: "Аккаунт"
                                  }, null, 8, ["modelValue", "search", "loading", "items", "onUpdate:search"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_combobox, {
                                    modelValue: $data.selectedType,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.selectedType) = $event)),
                                    label: "Тип транзакции",
                                    items: $data.types,
                                    hint: "DEPOSIT",
                                    density: "comfortable",
                                    variant: "solo-filled"
                                  }, null, 8, ["modelValue", "items"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_combobox, {
                                    modelValue: $data.selectedDeposit,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.selectedDeposit) = $event)),
                                    label: "Тип счёта",
                                    items: $data.deposit,
                                    density: "comfortable",
                                    variant: "solo-filled"
                                  }, null, 8, ["modelValue", "items"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_combobox, {
                                    modelValue: $data.selectedStatus,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.selectedStatus) = $event)),
                                    label: "Статус транзакции",
                                    items: $data.statuses,
                                    density: "comfortable"
                                  }, null, 8, ["modelValue", "items"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    type: "number",
                                    step: "any",
                                    min: "0",
                                    ref: "input",
                                    rules: [$data.filterNonPositive],
                                    label: "Сумма",
                                    modelValue: $data.valueNumber,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.valueNumber) = $event)),
                                    modelModifiers: { number: true }
                                  }, null, 8, ["rules", "modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        color: "green-darken-1",
                        variant: "text",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => ($options.sendTransaction()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Отправить ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        color: "red-darken-1",
                        variant: "text",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => ($data.showModel = false))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Закрыть ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ])
  ], 64))
}